import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { DEFAULT_CURRENCY_CODE } from '../../../common/constants';
import { GetExchangeRatesDocument } from '../../../common/gql/graphql';
import { DataService } from '../data/data.service';
import { StateService } from '../state.service';

@Injectable({
    providedIn: 'root',
})
export class CurrencyConverterService {
    private readonly baseCurrencyCode = DEFAULT_CURRENCY_CODE;

    exchangeRate$: Observable<{ currencyCode: string; conversionFactor: number }>;

    constructor(private dataService: DataService, private stateService: StateService) {
        this.exchangeRate$ = this.stateService
            .select((state) => state.displayCurrency)
            .pipe(
                switchMap((displayCurrency) => {
                    if (displayCurrency === this.baseCurrencyCode) {
                        return of({ currencyCode: displayCurrency, conversionFactor: 1 });
                    } else {
                        return this.dataService
                            .query(GetExchangeRatesDocument, {
                                base: this.baseCurrencyCode,
                                currencyCodes: [displayCurrency],
                            })
                            .pipe(
                                map(({ exchangeRates }) => {
                                    return {
                                        currencyCode: displayCurrency,
                                        conversionFactor:
                                            exchangeRates.rates.find(
                                                (r) => r.currencyCode === displayCurrency,
                                            )?.rate ?? 1,
                                    };
                                }),
                            );
                    }
                }),
            );
    }
}
