import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { map, take } from 'rxjs/operators';

import { GetActiveChannelDocument, GetActiveChannelQuery } from '../../common/gql/graphql';
import { DataService } from '../../core/providers/data/data.service';

import { CurrencyConverterService } from '../../core/providers/currency-converter/currency-converter.service';
import { Subscription } from 'rxjs';

/**
 * Cache the resolved promise so that the activeChannel only needs
 * to be fetched once on the first invocation of the pipe.
 */
let channelDataPromise: Promise<any>;

/**
 * A pipe which formats a price (always given as an integer by Venure) according
 * to the currencyCode of the current Channel.
 */
@Pipe({
    name: 'formatPrice',
    pure: false,
    standalone: true,
})
export class FormatPricePipe implements PipeTransform, OnDestroy {
    private latestValue: any = null;
    private latestReturnedValue: any = null;
    private latestCurrencyCode: string;
    private latestConversionFactor: number;
    private currencyChanged = false;
    private subscription: Subscription;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private dataService: DataService,
        private currencyConverterService: CurrencyConverterService,
    ) {
        this.subscription = this.currencyConverterService.exchangeRate$.subscribe(
            ({ currencyCode, conversionFactor }) => {
                this.latestConversionFactor = conversionFactor;
                this.latestCurrencyCode = currencyCode;
                this.currencyChanged = true;
                this.changeDetector.markForCheck();
            },
        );
    }

    transform(value?: number, currencyCode?: string) {
        if (value != null && (this.latestValue !== value || this.currencyChanged)) {
            this.latestValue = value;
            this.formatCurrency(value, currencyCode);
            this.currencyChanged = false;
        }
        return this.latestReturnedValue;
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    private formatCurrency(value: number, currencyCode?: string) {
        this.getActiveChannel().then((channel) => {
            if (currencyCode || this.latestCurrencyCode) {
                const formatter = Intl.NumberFormat(channel?.defaultLanguageCode, {
                    style: 'currency',
                    currency: currencyCode || this.latestCurrencyCode,
                });
                this.latestReturnedValue = formatter.format((value * this.latestConversionFactor) / 100);
                this.changeDetector.markForCheck();
            }
        });
    }

    private getActiveChannel(): Promise<GetActiveChannelQuery['activeChannel']> {
        if (!channelDataPromise) {
            try {
                channelDataPromise = this.dataService
                    .query(GetActiveChannelDocument, {}, { fetchPolicy: 'cache-only', ssr: true })
                    .pipe(
                        take(1),
                        map((data) => data.activeChannel),
                    )
                    .toPromise();
            } catch (e) {
                console.log(e);
            }
        }
        return channelDataPromise;
    }
}
